<template>
  <div
    :class="['chat-messages--items', direction]"
    :id="`message-${id}`"
    v-resize="onResize"
  >
    <div class="chat-messages--items__avatar">
      <v-avatar size="30" rounded>
        <span>{{ createNameIcon(author) }}</span>
      </v-avatar>
    </div>
    <div :class="['chat-messages--items__text', color]">
      {{ displayFullText ? doTruncateText(message, 500) : message }}
      <strong
        class="chat-messages--items__text--view"
        v-if="message.length >= 500"
        @click="displayFullText = !displayFullText"
      >
        {{ displayFullText ? 'Ver mais' : 'Ver menos' }}
      </strong>

      <v-menu bottom left v-if="type === 'sender' && lastMessage && !readonly">
        <template v-slot:activator="{ on, attrs }">
          <i
            class="fa-solid fa-ellipsis chat-messages--items__options"
            v-bind="attrs"
            v-on="on"
          />
        </template>

        <v-list dense class="pa-0">
          <v-list-item @click="editMessage">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <small v-if="updatedAt" class="color--gray">Editada</small>
    </div>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          class="chat-messages--items__file"
          :id="`btn-search-file-${index}`"
          :name="`btn-search-file-${index}`"
          v-if="files.length"
          v-on="on"
          @click="showFiles"
        >
          <v-icon small>fa-solid fa-paperclip</v-icon>
        </v-btn>
      </template>
      <span>Visualizar anexos</span>
    </v-tooltip>

    <DialogFilesMobile
      v-if="isFileMobile"
      :dialog="isFileMobile"
      @closeModal="closeModal"
    />

    <DialogEditMessage
      v-if="isEdit"
      :dialog="isEdit"
      :model="modelEdit"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { createNameIcon, doTruncateText } from '@/helpers/utils';

export default {
  components: {
    DialogEditMessage: () =>
      import('@/components/pages/supports/dialogs/dialog-edit-message'),
    DialogFilesMobile: () =>
      import('@/components/pages/supports/dialogs/dialog-files-mobile')
  },

  props: {
    type: {
      type: String,
      require: true
    },
    message: {
      type: String,
      require: true
    },
    author: {
      type: String,
      require: true
    },
    id: {
      type: String,
      require: true
    },
    updatedAt: {
      type: String
    },
    files: {
      type: Array,
      require: true
    },
    readonly: {
      type: Boolean,
      require: true
    },
    lastMessage: {
      type: Boolean,
      require: true,
      default: false
    },
    index: {
      type: Number
    },
    isDemandedTab: {
      type: Boolean,
      default: false,
      require: true
    },
    ticketId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    isEdit: false,
    isFileMobile: false,
    modelEdit: {},
    innerWidth: 0,
    displayFullText: true
  }),

  computed: {
    color() {
      return this.type === 'sender' ? 'text-gray' : 'text-blue';
    },

    direction() {
      return this.type === 'sender' ? 'right' : 'left';
    }
  },

  methods: {
    createNameIcon,
    doTruncateText,

    editMessage() {
      this.isEdit = true;

      this.modelEdit = Object.assign(
        {},
        {
          message: this.message,
          ticketMessageId: this.id,
          ticketId: this.ticketId,
          isDemandedTab: this.isDemandedTab
        }
      );
    },

    closeModal() {
      this.isEdit = false;
      this.isFileMobile = false;
    },

    showFiles() {
      this.$store.commit('support/filteredFiles', null);

      setTimeout(() => {
        this.$store.commit('support/filteredFiles', this.files);
      }, 1);

      if (this.innerWidth <= 600) {
        this.isFileMobile = true;
      }
    },

    onResize() {
      this.innerWidth = window.innerWidth;

      if (this.innerWidth >= 600) {
        this.isFileMobile = false;
      }
    }
  }
};
</script>
